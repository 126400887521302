
import Vue from "vue";

import http from "@/http";

import FileDownload from "js-file-download";

import { mdiMagnify, mdiCloudDownload } from "@mdi/js";

import { SurveyResponse, Survey } from "@/interfaces";

import MyOverview from "@/components/survey/MyOverview.vue";
import MyPager from "@/components/MyPager.vue";

export interface DataType {
  surveys: Survey[];
  page: number;
  hasPrev: boolean;
  hasNext: boolean;
}

export default Vue.extend({
  components: {
    MyOverview,
    MyPager
  },
  data(): DataType {
    return {
      surveys: [],
      page: 1,
      hasPrev: false,
      hasNext: false
    };
  },
  computed: {
    icon() {
      return {
        mdiCloudDownload
      };
    },

    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "アンケート",
          disabled: true,
          to: "/surveys"
        }
      ];
    }
  },
  watch: {
    async $route() {
      this.fetchData();
    }
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      const { page } = this.$route.query;

      if (page) {
        this.page = +page;
      }

      const { data } = await http.get<SurveyResponse>("surveys", {
        params: {
          page: this.page
        }
      });

      this.surveys = data.data;

      this.hasNext = !!data.next_page_url;
      this.hasPrev = !!data.prev_page_url;
      this.page = +data.current_page;
    },
    async next() {
      this.$router.replace({
        path: "/surveys",
        query: {
          page: (this.page + 1).toString()
        }
      });
    },
    async prev() {
      this.$router.replace({
        path: "/surveys",
        query: {
          page: (this.page - 1).toString()
        }
      });
    },
    async click() {
      const { data } = await http.get("download/surveys", {
        responseType: "blob"
      });

      FileDownload(data, "surveys.xlsx");
    }
  }
});
